import React from 'react';
import './App.css';
import {Route, HashRouter as Router, Redirect} from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import ForgottenPasswordPage from "./components/ForgottenPasswordPage/ForgottenPasswordPage";
import LoggedUserMainPage from "./components/LoggedUserMainPage/LoggedUserMainPage";
import SubscriptionPlans from "./components/SubscriptionPlans/SubscriptionPlans";
import {NotificationContainer} from 'react-notifications';
import MobileStripePayments from "./components/MobileStripePayments/MobileStripePayments";
import LocalStorageService from "./services/storageService/LocalStorageService";

function App() {

    return (
        <div className="App">
            <Router>
                <Route exact path="/" component={HomePage}/>
                <PrivateRoute  path="/user" component={LoggedUserMainPage} />
                <Route path="/forgottenPassword" component={ForgottenPasswordPage} />
                <Route path="/subscriptions" component={SubscriptionPlans} />
                <Route path="/stripe" component={MobileStripePayments} />
            </Router>

            <NotificationContainer/>
        </div>
    );
}

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest}
           render={props => LocalStorageService.getAccessToken() ? (<Component {...props}/>) :
               (<Redirect to={{
                   pathname: "/",
                   state: {from: props.location}
               }}/>)
           }
    />);


export default App;
