import React from 'react';
import './CardInfo.css'
import PropTypes from 'prop-types';
import VisaIcon from '../../../../assets/visa.png';
import MastercardIcon from '../../../../assets/mastercard.png'

const CardInfo = ({lastFourDigits, cardType}) => {
    const getCardIcon = (type) => {
        switch (type) {
            case 'visa':
                return VisaIcon;
            case 'mastercard':
                return MastercardIcon;
            default:
                break;
        }
    };

    return (
        <div className="">
            <div className="col-8 cards-wrapper m-auto d-flex">
                <div className="">
                    <div className="text-left">
                        <img className="pt-3" src={getCardIcon(cardType)} alt=""/>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="ml-5 bordered-card-name"> {cardType}</div>
                        <div className="name-and-card-number ml-5">
                            <p>**** {lastFourDigits}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

CardInfo.propTypes = {
    lastFourDigits: PropTypes.string,
    cardType: PropTypes.string
};

export default CardInfo;