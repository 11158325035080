import React, {Component} from 'react';
import SubscriptionsIcon from "../../assets/calendar-heading.svg";
import RenewalSwitch from "./RenewalSwitch/RenewalSwitch";
import './MySubscription.css';
import CardInfo from "./CardInfo/CardInfo";
import {backendCommunicator} from "../../utils/backend";
import UserDataService from "../../services/storageService/UserDataService";
import {Carousel, Spinner} from "react-bootstrap";
import PlatinumSubscribtion from '../../assets/plan-platinum.png';
import GoldSubscription from '../../assets/plan-gold.png';
import SilverSubscription from '../../assets/plan-silver.png';
import {Link} from "react-router-dom";
import SubscriptionPlan from "./SubscriptionPlan/SubscriptionPlan";
import config from "../../config";
import {loginResponseHandler} from "../../services/UserService/UserService";


class MySubscription extends Component {
    state = {
        cards: [],
        plans: [],
        loading: false
    };

    componentDidMount() {
        this.props.onActiveLinkChange(config.pageNames.MY_SUBSCRIPTION)
        this.setState({
            loading: true
        });
        backendCommunicator.get("/card").then(res => {
            this.setState({cards: res.data, loading: false})
        }).catch(err => {
            console.log(err)
        });

        backendCommunicator.get('/plan').then(res => {
            this.setState({plans: res.data})
        }).catch(err => {
            console.log(err)
        })
    }

    getPlanImage = () => {
        let userData = UserDataService.getUserData();

        if (userData.subscriptionPlan !== "") {
            switch (userData.subscriptionPlan) {
                case 'Silver':
                    return <img src={SilverSubscription} alt=""/>;
                case 'Gold':
                    return <img src={GoldSubscription} alt=""/>;
                case 'Platinum':
                    return <img src={PlatinumSubscribtion} alt=""/>;
                default:
                    break;
            }
        } else {
            return <h1>YOU HAVE NO SUBSCRIPTION</h1>
        }
    };

    autoRenewalRequestHandle = () => {
        backendCommunicator.put('/subscription/renewal').then(res => {
            return UserDataService.updateUserData('automaticRenewal')
        }).catch(err => {
            console.log(err)
        })
    };

    render() {
        let data = UserDataService.getUserData();
        let userName = data.firstName + ' ' + data.lastName;
        let isUserSubscribed = data.subscriptionPlan !== null;
        return (
            <div className="row">
                <div className="col-12 d-flex">
                    <div className="col-3 pre-content text-center">
                        <div className="text-center mt-3">
                            <div className="white-circle m-auto">
                                <img className="subicon" src={SubscriptionsIcon} alt=""/>
                            </div>
                            <h3 className="payments-heading pt-4">MY <p>SUBSCRIPTION</p></h3>
                        </div>
                        <div className="mt-5">
                            <p>Toggle auto renewal</p>
                            <RenewalSwitch isDisabled={!isUserSubscribed} onChange={this.autoRenewalRequestHandle}
                                           defaultValue={data.automaticRenewal}/>
                        </div>
                    </div>
                    {(() => {
                        if (this.state.loading) {
                            return <div className="col-10 text-center mt-5">
                                <Spinner animation={"border"} variant={"primary"} />
                            </div>
                        } else {
                            return <div className="col-9 text-center mt-2">
                                <div className="row text-center">
                                    <div className="col-12 subscriptions-images">
                                        {isUserSubscribed ? this.getPlanImage() :
                                            <div className="col-12 d-flex m-auto sub-plans">
                                                {this.state.plans.sort((a, b) => a.planOrdinal - b.planOrdinal)
                                                    .map(plan =>
                                                        <SubscriptionPlan key={plan.planOrdinal}
                                                                          ordinal={plan.planOrdinal}
                                                                          name={plan.planName}/>
                                                    )}
                                            </div>}
                                    </div>
                                </div>
                                <div className="text-center mb-3 offset-2">
                                    <Link to="/subscriptions" className="text-blue">View all plans</Link>
                                </div>
                                <div className="cards-info-wrapper">
                                    {this.state.cards.length > 0 ?
                                        <Carousel interval={null}
                                                  wrap={false}
                                                  nextIcon={<i className="fas fa-chevron-right chevron"></i>}
                                                  prevIcon={<i className="fas fa-chevron-left chevron"></i>}>
                                            {this.state.cards.map(card => (
                                                <Carousel.Item key={this.state.cards.indexOf(card)}>
                                                    <CardInfo name={userName} lastFourDigits={card.lastFourDigits}
                                                              cardType={card.brand}/>
                                                </Carousel.Item>
                                            ))}
                                        </Carousel>
                                        :
                                        <h1 className="col-8 cards-wrapper m-auto no-cards">NO REGISTERED CARDS</h1>
                                    }
                                </div>
                                <div className="col-8 pt-5 m-auto">
                                    <div>
                                        <p className="text-left"><span>Amount available</span> <span
                                            className="float-right">{data.accountBalance} €</span></p>
                                        <p className="text-left"><span>Total charged</span> <span
                                            className="float-right">{data.totalChargedkWh} kWh</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }
                    })()}
                </div>
            </div>
        );
    }
}

export default MySubscription;
