import React from 'react';
import {Elements} from 'react-stripe-elements'
import PaymentRequestForm from "./PaymentRequestForm";
import {userAuthRequest} from "../../../utils/backend";
import HeaderImage from '../../../assets/HEADER.png';
import './StripePayments.css'
import PaymentWithCard from "./PaymentWithCard";
import SilverPlan from "../../../assets/Silver card.png";
import GoldPlan from "../../../assets/Gold card (1).png";
import PlatinumPlan from "../../../assets/Platinum card.png";
import {Spinner} from "react-bootstrap";

class StripePayments extends React.Component {
    state = {
        cardId: '',
        token: '',
        planId: '',
        plan: {},
        clientSecret: '',
        selectedCard: {},
        loading: false
    };

    componentDidMount() {
        const regex = /token=(?<token>\w+)\&cardId=(?<cardId>-?\w+)\&planId=(?<planId>\w+)/;
        let match = regex.exec(window.location.href);
        if (match) {
            let {groups: {token, planId, cardId}} = match;

            this.setState({
                cardId: cardId,
                token: token,
                planId: planId
            });

            userAuthRequest.get(`/payment/web/jwt?token=${token}`).then(res => {

                sessionStorage.setItem('authtoken', res.headers.authorization)
                sessionStorage.setItem('refresh', res.headers.refresh)

                if (this.state.cardId.length !== 2) {
                    this.getSelectedCardInfo(res.headers.authorization, this.state.cardId)
                }

                userAuthRequest.get('/plan', {
                    'Authorization': res.headers.authorization
                }).then(response => {
                    response.data.map(plan => {
                        if (plan.id === Number(planId)) {
                            this.getClientSecret(res.headers.authorization, plan);

                            this.setState({
                                plan: plan
                            });
                        }
                    });

                }).catch(err => console.log('error in getting plans'))
            }).catch(err => {
                console.log('error in getting jwt')
            });
        }
    }

    getClientSecret = (auth, data) => {
        return userAuthRequest.post('/payment/secret', {
            amount: data.initialAmount,
            currency: 'eur',
            planId: data.planId
        }, {
            headers: {
                authorization: auth
            }
        }).then(res => {
            this.setState({
                clientSecret: res.data
            })
        }).catch(err => console.log(err));
    };

    getSelectedCardInfo = (auth, cardId) => {
        userAuthRequest.get('/card', {
            headers: {
                Authorization: auth
            }
        }).then(res => {
            console.log(res)
            res.data.map(card => {
                if (card.cardId === this.state.cardId) {
                    this.setState({selectedCard: card})
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }

    getPlanImage = (ordinal) => {
        switch (ordinal) {
            case 1:
                return <img src={SilverPlan} alt=""/>
            case 2:
                return <img src={GoldPlan} alt=""/>
            case 3:
                return <img src={PlatinumPlan} alt=""/>
            default:
                break;
        }
    };

    handleSubscribeToPlanWithCard = (paymentMethod) => {
        this.setState({loading: true})
        userAuthRequest.post(`/subscription/${this.state.plan.id}/customer`, {
            paymentMethodId: paymentMethod
        }, {
            headers: {
                Authorization: sessionStorage.getItem('authtoken')
            }
        }).then(res => {
            this.setState({loading: false})

            alert('success')
        }).catch(err => {
            this.setState({loading: false})

            console.log(err)
            alert('error')
        })
    };


    render() {
        let cardId = this.state.cardId
        return (
            <div>
                <div className="">
                    <div className="col-lg-4 col-sm-12 m-auto p-0">
                        <img className="header-img" src={HeaderImage} alt=""/>
                    </div>

                </div>

                {this.state.loading ?
                    <div className="col-12 text-center">
                        <Spinner animation={"border"} variant={"primary"}/>
                    </div>
                    : null}
                {(() => {
                    if (this.state.clientSecret && cardId.length === 2) {
                        return (
                            <Elements>
                                <PaymentRequestForm clientSecret={this.state.clientSecret}
                                                    cardId={this.state.cardId}
                                                    onPaymentComplete={this.props.onPaymentComplete}
                                                    plan={this.state.plan}
                                                    planImage={this.getPlanImage(this.state.plan.planOrdinal)}/>
                            </Elements>
                        )
                    }
                    if (cardId.length !== 2) {
                        return <PaymentWithCard
                            initialAmount={this.state.plan.initialAmount}
                            planImage={this.getPlanImage(this.state.plan.planOrdinal)}
                            onSubscribeClick={() => this.handleSubscribeToPlanWithCard(this.state.cardId)}
                            card={this.state.selectedCard}/>
                    }
                })()}


            </div>

        )
    };
}

StripePayments.propTypes = {};

export default StripePayments;