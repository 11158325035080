import React from 'react';
import PropTypes from 'prop-types';
import CardInfo from "./CardInfo/CardInfo";

const PaymentWithCard = props => {
    return (
        <div className="">
            <div className="col-lg-4 col-sm-12 m-auto d-flex">
                <div className="mt-4 ml-4 img-wrapper ">
                    {props.planImage}
                </div>
                <div className="sc-plan-price d-flex ml-4">
                   <p>{props.initialAmount}</p> <p>€</p>
                </div>
            </div>

        <div className="mt-4">
            <CardInfo cardType={props.card.brand}
                      lastFourDigits={props.card.lastFourDigits} />

        </div>
             <div className="col-sm-12 col-lg-4 p-0 m-auto text-center fixed-bottom">
                <button onClick={() => props.onSubscribeClick()} className="sc-sub-btn-card">SUBSCRIBE</button>
            </div>
        </div>
    );
};

PaymentWithCard.propTypes = {

};

export default PaymentWithCard;