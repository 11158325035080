import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {injectStripe} from 'react-stripe-elements';
import CardElement from './CardElement';
import {Spinner} from "react-bootstrap";
import NotificationService from "../../../services/NotificationService/NotificationService";
import {userAuthRequest} from "../../../utils/backend";
import HeaderImage from "../../../assets/HEADER.png";

const StripeAddCard = props => {

    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);

    useEffect(() => {

        let regex = /token=(?<foundToken>\w+)/;
        let match = regex.exec(window.location.href);

        //if there is query parameter, token will be extracted
        if (match) {
            let { groups: {foundToken}} = match;
            setToken(foundToken)
        } else {
            alert('token is missing');
        }

        if (token) {
            userAuthRequest.get(`/payment/web/jwt?token=${token}`).then(res => {

                sessionStorage.setItem('authtoken', res.headers.authorization)
                sessionStorage.setItem('refresh',  res.headers.refresh)
            }).catch(err => {
                console.log(err)
            })
        }
    }, [token]);


    const handleSubmit = (ev) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();
        setLoading(true)
        // You can also use handleCardPayment with the PaymentIntents API.
        // See our handleCardPayment documentation for more:
        // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment
        userAuthRequest.post('/card/secret', {}, {
          headers: {
              "Authorization": sessionStorage.getItem('authtoken')
          }
        }).then(res => {
            if (res.status === 200) {
                props.stripe.handleCardSetup(res.data).then(response => {
                  if (!response.error) {
                      props.onCardAdded(response.setupIntent.payment_method, setLoading);
                  } else {
                      NotificationService.notify('error', ' ', "There was an error")
                      setLoading(false)
                  }
                })
            } else {
                setLoading(false)
            }
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    };
    return (
        <div>
            <div className="col-lg-4 m-auto p-0">
                <img className="header-img" src={HeaderImage} alt=""/>
            </div>

            {loading ?
                <div className="col-12 text-center">
                    <Spinner animation={"border"} variant={"primary"}/>
                </div>
                : null}
            <div className="col-12 text-center mt-5">
                <h1>Register card</h1>
            </div>

            <form onSubmit={handleSubmit} className="text-center mt-5">
                <CardElement/>

                <div className="col-sm-12 col-lg-4 m-auto p-0 fixed-bottom">
                    <button type="submit" className="mt-2 sub-btn">SUBMIT</button>
                </div>
            </form>
        </div>

    )
};

StripeAddCard.propTypes = {
    onCardAdded: PropTypes.func
};

export default injectStripe(StripeAddCard);