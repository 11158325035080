import React, {Component} from 'react';
import {userAuthRequest} from "../../../utils/backend";
import SocialLoginButton from "../SocialButton/SocialLoginButton";
import TwitterIcon from '../../../assets/twitter-icon-circle-blue-logo-preview.png';
import PropTypes from 'prop-types';

class TwitterLoginButton extends Component {
    state = {
        twitterUrl: '',
        id: '',
        accessToken: '',
        oauth_token: '',
        oauth_verifier: '',
    };

    componentDidMount() {

        userAuthRequest.get(`/user/login/twitter?callbackURL=${process.env.REACT_APP_TWITTER_CALLBACK_URL}`)
            .then(response => {
                this.setState({
                    twitterUrl: response.data,
                })
            }).catch(error => console.log(error));
    }

    render() {
        return (
            <a href={this.state.twitterUrl}>
                    <span>
                    <SocialLoginButton icon={TwitterIcon}/>
                    </span>
            </a>

        );
    }
}

TwitterLoginButton.propTypes = {
    history: PropTypes.object
};

export default TwitterLoginButton;