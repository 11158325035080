import React from 'react';
import PropTypes from 'prop-types';
import '../../LoginForm/LoginForm.css';
import './UpdatePasswordForm.css';
import {backendCommunicator} from "../../../utils/backend";
import NotificationService from "../../../services/NotificationService/NotificationService";
import {Spinner} from "react-bootstrap";

class UpdatePasswordForm extends React.Component {
    state = {
        hasError: false,
        error: '',
        loading: false
    };

    handleUpdatePasswordRequest = (event) => {
        event.preventDefault();
        if (this.state.newPassword === this.state.retypePassword) {

            this.setState({
                loading: true
            });

            backendCommunicator.patch('/user/password', {
                oldPassword: this.state.password,
                newPassword: this.state.newPassword
            }).then(res => {
                this.setState({
                    loading: false
                });

                NotificationService.notify('success', "Password updated successfully.")
                this.props.closeModal()
            }).catch(err => {
                if (err.response.status === 403) {
                    this.setState({
                        loading:false,
                        hasError: true,
                        error: 'Wrong old password.'
                    })
                } else if (err.response.status === 400) {
                    this.setState({
                        loading:false,
                        hasError: true,
                        error: 'Fill all the fields.'
                    })
                }
            })
        } else {
            this.setState({
                loading:false,
                hasError: true,
                error: "New passwords don't match."
            })
        }
    };

    enterPressed = (event) => {
        if (event.key === 'Enter') {
            return this.handleUpdatePasswordRequest(event)
        }
    };

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value})
    };

    render() {
        return (
            <div className="update-form-wrapper">
                <div className="p-5 up-heading">
                    <h1 className="text-center ">UPDATE PASSWORD</h1>
                </div>

                <form action="" onKeyPress={this.enterPressed} className="updatePasswordForm">
                    <div className="input-group inputBottomLine">
                        <input onChange={this.handleInputChange} type="password" name="password"
                               className="form-control inputField up-field"
                               placeholder="Old password"/>
                    </div>
                    <div className="input-group inputBottomLine mt-3">
                        <input onChange={this.handleInputChange} type="password" name="newPassword"
                               className="form-control inputField up-field" placeholder="New password"/>
                    </div>
                    <div className="input-group inputBottomLine mt-3">
                        <input onChange={this.handleInputChange} type="password" name="retypePassword"
                               className="form-control inputField up-field" placeholder="Retype password"/>
                    </div>

                    {this.state.hasError ? <div className="alert alert-danger mt-2" role="alert">{this.state.error}</div> : null}
                    {this.state.loading &&
                    <div className="text-center pt-3 pb-3"><Spinner animation="border" variant="info"/></div>}

                    <div className="text-center mt-4">
                        <button onClick={this.handleUpdatePasswordRequest} className="profile-update-btn ml-0"

                                type="submit">UPDATE
                        </button>
                    </div>
                </form>

            </div>
        );
    }
}

UpdatePasswordForm.propTypes = {
    closeModal: PropTypes.func
};

export default UpdatePasswordForm;