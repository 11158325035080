import React from "react";
import {injectStripe} from "react-stripe-elements";
import PropTypes from "prop-types";
import SilverPlan from '../../../assets/Silver card.png'
import GoldPlan from '../../../assets/Gold card (1).png'
import PlatinumPlan from '../../../assets/Platinum card.png'
import GPay from '../../../assets/Google_Pay_(GPay)_Logo.svg';
import ApplePay from '../../../assets/2000px-Apple_Pay_logo.svg'
import {userAuthRequest} from "../../../utils/backend";

class PaymentRequestForm extends React.Component {
    constructor(props) {
        super(props);
        let {stripe, clientSecret, onPaymentComplete, plan} = props;

        // For full documentation of the available paymentRequest options, see:
        // https://stripe.com/docs/stripe.js#the-payment-request-object
        const paymentRequest = this.props.stripe.paymentRequest({
            country: 'DE',
            currency: 'eur',
            total: {
                label: 'Demo total',
                amount: plan.initialAmount,
            },
        });

        paymentRequest.on('paymentmethod', function (ev) {

            // Confirm the PaymentIntent without handling potential next actions (yet).
            stripe.confirmCardPayment(
                props.clientSecret,
                {payment_method: ev.paymentMethod.id},
                {handleActions: false}
            ).then(function (confirmResult) {
                console.log(confirmResult);
                if (confirmResult.error) {
                    // Report to the browser that the payment failed, prompting it to
                    // re-show the payment interface, or show an error message and close
                    // the payment interface.
                    console.log("Payment request fail at line 38");
                    ev.complete('fail');
                } else {
                    // Report to the browser that the confirmation was successful, prompting
                    // it to close the browser payment method collection interface.
                    ev.complete('success');
                    // Let Stripe.js handle the rest of the payment flow.
                    stripe.confirmCardPayment(clientSecret).then(function (result) {
                        console.log(result);
                        if (result.error) {
                            console.log("Payment request fail at line 48");
                            // The payment failed -- ask your customer for a new payment method.
                        } else {

                            // The payment has succeeded.
                            onPaymentComplete(result.paymentIntent.id);
                        }
                    });
                }
            });
        });

        paymentRequest.canMakePayment().then((result) => {
            this.setState({canMakePayment: !!result});
        });

        this.state = {
            canMakePayment: false,
            paymentRequest,
        };
    }


    getPaymentType = (paymentType) => {
        switch (paymentType) {
            case '-1':
                return <img className="gpay-img pt-5" src={ApplePay} alt=""/>
            case '-2':
                return <img className="" src={GPay} alt=""/>
            default:
                break;
        }

    };


    render() {
        console.log("Rendering payment request btn");
        return this.state.canMakePayment ? (
            <div>
                <div className="plan-to-subscribe text-center d-flex m-auto col-12">
                    <div className="mt-4 ml-4 img-wrapper">
                        {this.props.planImage}
                    </div>
                    <div className="sc-plan-price d-flex ml-4">
                       <p> {this.props.plan.initialAmount}</p> <p>€</p>
                    </div>
                </div>

                <div className="text-center">
                    {this.getPaymentType(this.props.cardId)}
                </div>

                <div className="col-sm-12 col-lg-4 p-0 text-center fixed-bottom">
                    <button onClick={() => this.state.paymentRequest.show()} className="sc-sub-btn">SUBSCRIBE</button>
                </div>
            </div>
        ) : null;
    }
}

PaymentRequestForm.propTypes = {
    clientSecret: PropTypes.string,
    onPaymentComplete: PropTypes.func,
    amount: PropTypes.number,
    currency: PropTypes.string
};

export default injectStripe(PaymentRequestForm);